import { Trans } from "@lingui/macro";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useLocalization } from "gatsby-theme-i18n";
import React, { useState } from "react";
import Mail from "../services/MailService";
import Modal from "./Modal";
import { theme } from "../themeV2";
import { isValidMail, isValidLength, isDefined } from "../utils/validations";
import { successBody } from "./ThankRequestModal";

function ProductModal({ onClose, subject, product = "alternatives-request", buttonColor = "secondary", showSuccess = true }) {
  const { locale } = useLocalization();
  const [data, setData] = useState({
    subject,
    product,
    type: "product",
    from: "noreply@allfunds.com",
    name: "",
    to: "",
  });
  const [responseSuccess, setResponseSuccess] = useState(false);

  const sendRequest = () => {
    Mail.sendMail(data, { lang: locale }).then(({ status }) => {
      setResponseSuccess(status === 200 && showSuccess);
      if (status === 200 && !showSuccess) {
        onClose();
      }
    });
  };

  const handleNameChange = (value) => {
    setData({ ...data, name: value });
  };

  const handleEmailChange = (value) => {
    setData({
      ...data,
      to: [value],
      email: value,
    });
  };

  const validate = () => {
    const emailValid = isDefined(data.email) && isValidMail(data.email) == null;
    const nameValid = isDefined(data.name) && isValidLength(data.name, 3) == null;

    return emailValid && nameValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      sendRequest();
    } else {
      alert("Form is invalid! Please check the fields...");
    }
  };

  const buttonSuccessAction = () => {
    onClose();
  };

  const defaultBody = () => {
    return (
      <Stack spacing={1.5} textAlign="end">
        <Typography variant="h4" textAlign="center">
          <Trans>Send request information</Trans>
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <Stack spacing={2}>
              <TextField
                label={<Trans>Full name</Trans>}
                variant="outlined"
                fullWidth
                onChange={(e) => handleNameChange(e.target.value)}
                error={isValidLength(data.name, 3)}
                helperText={isValidLength(data.name, 3)}
                value={data.name}
                required
              />
              <TextField
                label={<Trans>Email</Trans>}
                variant="outlined"
                fullWidth
                onChange={(e) => handleEmailChange(e.target.value)}
                error={isValidMail(data.email)}
                helperText={isValidMail(data.email)}
                value={data.email}
                required
              />
            </Stack>
            <Stack alignItems="end">
              <Button
                size="large"
                color={buttonColor}
                variant="contained"
                sx={{
                  height: "50px",
                  width: "fit-content",
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: theme.palette.colors.red.dark,
                  },
                }}
                type="submit"
                disabled={!validate()}
              >
                <Typography
                  variant="info"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "white !important",
                  }}
                >
                  <Trans>Request information</Trans>
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    );
  };

  return (
    <Modal open width={450} onClose={onClose} backgroundColor="white">
      { (responseSuccess && showSuccess) ? successBody({buttonSuccessAction}) : defaultBody() }
    </Modal>
  );
}

export default ProductModal;
