import React from "react";
import { Trans } from "@lingui/macro";
import { Button, Stack, Typography, Box } from "@mui/material";
import { theme } from "../themeV2";
import Modal from "./Modal";
import logoNegative from "../images/logo-negative.png";

export const successBody = ({ buttonSuccessAction }) => (
  <Stack spacing={1} alignItems="center" textAlign="center">
    <Stack spacing={3} alignItems="center">
      <img
        alt="allfunds_logo"
        src={logoNegative}
        width="200"
          />
      <Typography variant="h4">
        <Trans>Thank you for your request</Trans>
      </Typography>
    </Stack>
    <Stack spacing={1}>
      <Typography variant="body" color="rgba(14, 35, 64, 0.60)">
        <Trans>
          We will contact you shortly
        </Trans>
      </Typography>
      <Typography variant="body" color="rgba(14, 35, 64, 0.60)">
        <Trans>Thanks for your interest!</Trans>
      </Typography>
      <Stack spacing={2}>
        <Box>
          <Typography variant="body" color="rgba(14, 35, 64, 0.60)">
            <Trans>Allfunds</Trans>
          </Typography>
        </Box>
        <Box>
          <Button
            size="large"
            color="secondary"
            variant="contained"
            sx={{
                  height: "50px",
                  width: "80%",
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: theme.palette.colors.red.dark,
                  },
                }}
            onClick={buttonSuccessAction}
              >
            <Typography
              variant="info"
              sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "white !important",
                    fontWeight: 600,
                  }}
                >
              <Trans>Go home</Trans>
            </Typography>
          </Button>
        </Box>
      </Stack>
    </Stack>
  </Stack>
);

function ThankRequestModal({ onClose, buttonSuccessAction }) {
  return (
    <Modal open width={450} onClose={onClose} backgroundColor="white">
      {successBody({ buttonSuccessAction })}
    </Modal>
  );
}

export default ThankRequestModal;
